@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(64%) sepia(33%) saturate(3798%) hue-rotate(335deg)
      brightness(101%) contrast(101%);
  }
  html {
    @apply scroll-smooth;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
